import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { connect } from 'react-redux';
import createStore from './core__data-notfound-store';
import DataNotFound from './component/core__dataNotFoundReact';
import ScDecoder from 'Services/json/core__decoder';
import { TranslationsProvider } from 'Services/translations/core__translations';
export const Translations = new TranslationsProvider('DataNotFoundApp');

class DataNotFoundApp {
  constructor(properties) {
    Object.assign(this, properties);
    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }
  start(data, reduxSelector) {
    Translations.setAll(data);

    const ReactInstance = this.getReactApp();

    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );
    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }
  getReactApp() {
    const mapStateToProps = () => {
      return {
        appConfig: this.appConfig,
        Translations: Translations,
      };
    };
    const mapDispatchToProps = {
      Translations: Translations,
    };

    DataNotFound.serverFetch = {
      url: this.url,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
      createStore: createStore,
      strToTranslate: [
        'text.oops',
        'text.page.not.found',
        'text.here.are.a.few.links.that.may.be.helpful',
        'text.back',
        'text.register',
        'text.sportsbook',
        'text.horseracing',
        'text.soccer',
        'text.help',
        'text.casino',
      ],
    };

    return connect(mapStateToProps, mapDispatchToProps)(DataNotFound);
  }
}
export default DataNotFoundApp;
