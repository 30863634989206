import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';

export const dataReducer = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const reducer = combineReducers({
  data: dataReducer,
});

const composeEnhancers = getComposeEnhancers({ name: 'data-not-found-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
