import styled, { css } from 'styled-components';
import { GREYS } from 'UI/globals/colours';

export const Back = styled.p`
  margin-block: 20px;
  cursor: pointer;
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${GREYS.white};
    `}
`;
export const WR = styled.div`
  margin-inline: auto;
`;

export const BCK = styled.span`
  position: absolute;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
  margin-top: 4px;
`;
