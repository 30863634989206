import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NoEvent from 'UI/globals/NoEvent';
import { generateNoEventsDataArray } from 'Services/global/core__noEventsArrayGenerator';
import {
  Back,
  WR,
  BCK as BCK_Local,
} from 'UI/apps/DataNotFoundApp/DataNotFound';
import { BCK as BCK_Core } from 'CORE__UI/apps/DataNotFoundApp/core__dataNotFoundReact';
import { handleBackNavigation } from 'Services/global/core__handleBackNavigation';
import { FSBTheme } from 'Services/core__fsb-theme';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { getComponent } from 'Services/core__imports';
const BCK = getComponent(BCK_Local, BCK_Core);

/**
 * No data found component
 * @param {object} appConfig,
 * @param {boolean} hideBack,
 * @param {object} Translations // that require if u import this file directly in other component
 */
const DataNotFound = ({ appConfig, hideBack, Translations }) => {
  const [showNotFound, setShowNotFound] = useState(
    appConfig.visible === undefined ? true : appConfig.visible
  );

  useEffect(() => {
    const displayNotFoundListener = PubSub.listen(
      PubsubEvents.DisplayNotFound,
      visible => {
        setShowNotFound(visible);
      }
    );
    if (typeof appConfig.visible === undefined) {
      setShowNotFound(true);
    }

    return () => {
      displayNotFoundListener?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const iosStaticback = !appConfig.requestURL.includes('/ios/');

  const onBackNavigation = () => {
    appConfig.backurl
      ? (window.location.href = appConfig.backurl)
      : handleBackNavigation(window.sessionStorage.getItem('root'));
  };

  return (
    (showNotFound && (
      <FSBTheme theme={appConfig?.theme}>
        <WR>
          {!hideBack && iosStaticback && (
            <Back onClick={onBackNavigation}>
              <I iconName={getIcon('BACK')} size={24} marginR={8} />
              <BCK>{Translations.get('text.back')}</BCK>
            </Back>
          )}
          <NoEvent
            showJNStyle={true}
            title={Translations.get('text.oops')}
            subTitle={Translations.get('text.page.not.found')}
            info={Translations.get(
              'text.here.are.a.few.links.that.may.be.helpful'
            )}
            noEventData={generateNoEventsDataArray(
              appConfig?.nodatatitles,
              appConfig?.nodatalinks
            )}
            Translations={Translations}
          />
        </WR>
      </FSBTheme>
    )) ||
    ''
  );
};
DataNotFound.propTypes = {
  appConfig: PropTypes.object,
  hideBack: PropTypes.bool,
  showJNStyle: PropTypes.bool,
  Translations: PropTypes.object,
};
DataNotFound.defaultProps = {
  Translations: {},
};
export default DataNotFound;
